import {
  FX_ORDER_PERIMETER_KEY,
  type FxAlgoOrderDimensions,
  type FxFixingOrderDimensions,
  type FxLimitOrderDimensions,
  type FxOrderDimensions,
  type FxOrderDimensionsValues,
  type FxOrderPerimeterKey,
  type FxOrderProductKey,
  type FxOrderProfile,
  type FxOrderProfileAlgoColumns,
  type FxOrderProfileColumns,
  type FxOrderProfileFixingColumns,
  type FxOrderProfileLimitColumns,
  type FxOrderProfileRow,
} from "@services/fx/model/order";
import {
  FX_CASH_PERIMETER_KEY,
  type FxCashMarginDimensionsValues,
  type FxCashPerimeterKey,
  type FxCashProductKey,
  type FxCashProfile,
  type FxCashProfileColumns,
  type FxCashProfileMarginColumns,
  type FxCashProfileRejectedColumns,
  type FxCashProfileRow,
  type FxCashProfileTieringColumns,
  type FxCashTieringDimensionsValues,
} from "@services/fx/model/cash";
import { isDefined, makeValuesPredicate } from "@sgme/fp";

//todo-5198 FxOrderProfileRow should be based on FxProfileMarginGrid<FxCashProfileColumns> (to match cash)
export type FxMarginGrid = FxOrderProfile | FxCashProfile;
export type FxProductKey = FxOrderProductKey | FxCashProductKey;

//todo-5198 FxOrderProfileRow should be based on FxProfileMarginGrid<FxCashProfileColumns> (to match cash)
export type FxMarginGridRows = FxOrderProfileRow[] | FxCashProfileRow[];
export type FxProfileColumns = FxCashProfileColumns | FxOrderProfileColumns;

export const PERIMETER_KEYS = [
  FX_ORDER_PERIMETER_KEY,
  FX_CASH_PERIMETER_KEY,
] as const;
export type FxPerimeterKey = FxOrderPerimeterKey | FxCashPerimeterKey;
export const isPerimeterKey = makeValuesPredicate(PERIMETER_KEYS);

export type FxDimensionsValues =
  | FxOrderDimensionsValues
  | FxCashMarginDimensionsValues
  | FxCashTieringDimensionsValues;

// type guard are in this file rather than in  order.ts or cash.ts files
// because we use perimiter types as an input parameter to cover broad cases

export const isOrderProfile = (
  profile: FxMarginGrid | undefined,
  perimeterKey: FxPerimeterKey
): profile is FxOrderProfile => perimeterKey === FX_ORDER_PERIMETER_KEY;

export const isFxOrderProfileColumns = (
  columns?: FxProfileColumns
): columns is FxOrderProfileColumns =>
  isDefined((columns as FxOrderProfileAlgoColumns).algoTypeDimension);

export const isFxLimitOrderProfileColumns = (
  columns?: FxProfileColumns
): columns is FxOrderProfileLimitColumns =>
  isDefined((columns as FxOrderProfileLimitColumns).limitOrderTypeDimension);

export const isFxFixingOrderProfileColumns = (
  columns?: FxProfileColumns
): columns is FxOrderProfileFixingColumns =>
  isDefined((columns as FxOrderProfileFixingColumns).fixingSourceDimension);

export const isFxAlgoOrderProfileColumns = (
  columns?: FxProfileColumns
): columns is FxOrderProfileAlgoColumns =>
  isDefined((columns as FxOrderProfileAlgoColumns).algoTypeDimension);

export const isFxCashProfileColumns = (
  columns: FxProfileColumns | undefined
): columns is FxCashProfileColumns =>
  isDefined((columns as FxCashProfileColumns)?.onshoreOffshoreDimension);

export const isFxCashProfileMarginColumns = (
  columns: FxProfileColumns | undefined
): columns is FxCashProfileMarginColumns =>
  (columns as FxCashProfileMarginColumns)?.isRejected === "False" &&
  isDefined((columns as FxCashProfileMarginColumns).marginUnit);

export const isFxCashProfileTieringColumns = (
  columns: FxProfileColumns | undefined
): columns is FxCashProfileTieringColumns =>
  (columns as FxCashProfileTieringColumns)?.isRejected === "False" &&
  isDefined((columns as FxCashProfileTieringColumns).tieringResult);

export const isFxCashProfileRejectedColumns = (
  columns: FxProfileColumns | undefined
): columns is FxCashProfileRejectedColumns =>
  (columns as FxCashProfileRejectedColumns)?.isRejected === "True";

export const isFxFixingOrderDimensions = (
  dimensions: FxOrderDimensions
): dimensions is FxFixingOrderDimensions =>
  isDefined((dimensions as FxFixingOrderDimensions).fixingSourceDimension);

export const isFxAlgoOrderDimensions = (
  dimensions: FxOrderDimensions
): dimensions is FxAlgoOrderDimensions =>
  isDefined((dimensions as FxAlgoOrderDimensions).algoTypeDimension);

export const isFxLimitOrderDimensions = (
  dimensions: FxOrderDimensions
): dimensions is FxLimitOrderDimensions =>
  isDefined((dimensions as FxLimitOrderDimensions).limitOrderTypeDimension);
