import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { styled } from 'styled-components';
import { useAppSelector } from '@hooks/useAppSelector';
import { changeTieringResult } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import type { FxCashProfileTieringColumns } from '@services/fx/model/cash';
import { isDefined } from '@sgme/fp';
import { HelpCenter } from '@components/HelpCenter';

type TieringSelectProps = {
  tieringDimension: Record<string, string[]>;
};

const TieringSelect: FC<TieringSelectProps> = ({ tieringDimension }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpened] = useState(false);
  const toggle = () => setIsOpened((value) => !value);

  const tieringValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxCashProfileTieringColumns).tieringResult,
  );

  const setTieringValue = (value: string) => () => {
    dispatch(changeTieringResult({ value }));
  };

  return (
    <div className={'form-group'} data-e2e="mediaDimension">
      <div className={'d-flex flex-between'}>
        <label className="text-primary fw-medium" htmlFor="mediaDimension">
          <FormattedMessage id="fx.editProfile.fx-cash-rfq-perimeter.tiering" />
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <Dropdown toggle={toggle} isOpen={isOpen} direction="down" data-e2e="tiering-result-select">
        <StyledDropdownToggle caret color="discreet-primary" data-nodrag className="w-100 btn-icon-end bg-lvl4">
          {isDefined(tieringValue) && tieringValue !== '' ? (
            <span>{tieringValue}</span>
          ) : (
            <span className="text-secondary fw-light">
              <FormattedMessage id="fx.editProfile.fx-cash-rfq-perimeter.tiering.placeholder" />
            </span>
          )}
        </StyledDropdownToggle>

        <DropdownMenu className="w-100" style={{ maxHeight: '350px', overflowY: 'auto' }}>
          {isDefined(tieringDimension) && Object.entries(tieringDimension).map(([tieringCategory, allTierings]) => (
            <div key={tieringCategory}>
              <DropdownItem disabled>{tieringCategory}</DropdownItem>

              {allTierings.map((tieringItem) => (
                <DropdownItem key={tieringItem} onClick={setTieringValue(tieringItem)}>
                  {tieringItem}
                </DropdownItem>
              ))}
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default TieringSelect;

const StyledDropdownToggle = styled(DropdownToggle)`
  position: relative;
  justify-content: flex-start;

  &::after {
    position: absolute;
    right: 8px;
  }
`;
